<template>
  <a-card>
    <div class="query-wrap">
      <a-form-model layout="inline" :model="queryParam" v-bind="{}" ref="queryForm">
        <a-form-model-item :wrapper-col="{}">
          <a-space>
            <a-button type="primary" @click="query">查询</a-button>
            <a-button type="danger" icon="cloud-upload" @click="visible = true" v-if="$checkPermission('Action:ExternalData:Push')">推送数据</a-button>
          </a-space>
        </a-form-model-item>
      </a-form-model>
    </div>
    <a-modal title="数据推送" :visible="visible" :confirm-loading="uploading" @ok="push" @cancel="handleCancel">
      <a-form-model ref="uploadForm" :model="form" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-form-model-item label="批次号" prop="batchId">
          <a-input v-model="form.batchId" placeholder="请输入数据批次编号" />
        </a-form-model-item>
        <a-form-model-item label="文件" prop="file">
          <a-upload :file-list="fileList" :remove="handleRemove" :before-upload="beforeUpload">
            <a-button><a-icon type="upload" />选择文件</a-button>
          </a-upload>
        </a-form-model-item>
        <a-form-model-item label="模板">
          <a href="/骏伯外呼数据回推模板.xlsx" target="_blank"><a-icon type="file-excel" /> 下载模板</a>
        </a-form-model-item>
        <a-form-model-item label="备注" prop="remark">
          <a-input v-model="form.remark" type="textarea" placeholder="备注信息" />
        </a-form-model-item>
      </a-form-model>
    </a-modal>
    <s-table ref="table" size="default" :rowKey="record => record.id" :columns="columns" :data="loadData">
      <span slot="message" slot-scope="text, record">
        <a-icon v-if="record.success" type="check-circle" theme="twoTone" two-tone-color="#52c41a" />
        <a-icon v-else type="close-circle" theme="twoTone" two-tone-color="#ff4d4f" />
        {{ toJSON(text).message }}
      </span>
      <span slot="uri" slot-scope="text">
        <a :href="text" target="_blank"><a-icon type="cloud-download" /> 下载数据文件</a>
      </span>
    </s-table>
  </a-card>
</template>

<script>
import { STable } from '@/components'

export default {
  components: {
    STable
  },
  data() {
    return {
      visible: false,
      uploading: false,
      labelCol: { span: 4 },
      wrapperCol: { span: 18 },
      fileList: [],
      form: {
        file: null,
        remark: null,
        batchId: null
      },
      columns: [
        {
          title: '编号',
          dataIndex: 'id',
          width: 180
        },
        {
          title: '批次号',
          dataIndex: 'batchId',
          width: 240
        },
        {
          title: '文件地址',
          dataIndex: 'uri',
          scopedSlots: { customRender: 'uri' },
          width: 150
        },
        {
          title: '用户',
          dataIndex: 'user',
          width: 100
        },
        {
          title: '备注',
          dataIndex: 'remark',
          width: 250
        },
        {
          title: '时间',
          dataIndex: 'datetime',
          width: 160
        },
        {
          title: '消息',
          dataIndex: 'message',
          scopedSlots: { customRender: 'message' },
          width: 240
        }
      ],
      // 查询条件参数
      queryParam: {},
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        const params = Object.assign(parameter, this.queryParam)

        return this.$http.get('/external/data/push/findAll', { params }).then(res => {
          return res.body
        })
      }
    }
  },
  methods: {
    query() {
      this.$refs.table.refresh()
    },
    handleRemove(file) {
      const index = this.fileList.indexOf(file)
      const newFileList = this.fileList.slice()
      newFileList.splice(index, 1)
      this.fileList = newFileList
    },
    beforeUpload(file) {
      this.fileList = [file]
      return false
    },
    handleCancel() {
      this.visible = false
    },
    push() {
      const formData = new FormData()
      formData.append('file', this.fileList[0])
      formData.append('remark', this.form.remark)
      formData.append('batchId', this.form.batchId)

      this.uploading = true
      this.$http
        .post('/external/data/push/push', formData)
        .then(() => {
          this.visible = false
          this.$message.success('推送成功')
          this.$refs.table.refresh()
        })
        .catch(err => {
          this.$message.error(err.message)
        })
        .finally(() => {
          this.uploading = false
        })
    },
    toJSON(text) {
      try {
        return JSON.parse(text)
      } catch (e) {
        return {}
      }
    }
  }
}
</script>

<style scoped>
.query-wrap {
  padding-bottom: 20px;
}
</style>
